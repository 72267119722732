import React, { useEffect, useState } from 'react';
import FinanceSearchBar from './FinanceSearchBar';
import { AppBar, Tabs, Tab, Box, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Typography, IconButton, TextField } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, LineChart, Line } from 'recharts';
import { Card, CardContent, Grid, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AssetDetailsModal from './AssetDetailsModal'; // Import the AssetDetailsModal component
import FinanceContentMobile from './FinanceContentMobile';
import { format } from 'date-fns'; // Import the format function
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const theme = createTheme({
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent!important',
                },
            },
        },
    },
});

const FinanceContent = ({ userId, permissions, contactsData , assetTypes , setSelectedAsset, selectedAsset, setContactsData, setUpdateTrigger}) => {
    const [isMobile, setIsMobile] = useState(false);
    const [financeCountMessage, setFinanceCountMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [tabIndex, setTabIndex] = useState(0);
    const [assetsData, setAssetsData] = useState([]);
    const [filteredSoldAssets, setFilteredSoldAssets] = useState([]);
    const [filteredLeasedAssets, setFilteredLeasedAssets] = useState([]);
    const [totalAssets, setTotalAssets] = useState(0);
    const [totalValue, setTotalValue] = useState(0);
    const [totalProfit, setTotalProfit] = useState(0);
    const [topAssets, setTopAssets] = useState([]);
    const [totalAgentExpense, setTotalAgentExpense] = useState(0);
    const [topAssetsCount, setTopAssetsCount] = useState(5);
    const [agentPerformanceData, setAgentPerformanceData] = useState([]);
    const [assetDistribution, setAssetDistribution] = useState([]);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [profitOverTime, setProfitOverTime] = useState([]);
    const { t } = useTranslation();
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
    const isAdmin = (permissions || []).includes('finance_access');
    const [verifiedAssets, setVerifiedAssets] = useState(new Set());
    const [expenseBreakdown, setExpenseBreakdown] = useState([
        { name: t('Advertising and Listing Fees'), value: 0 },
        { name: t('Agent Commissions'), value: 500 },
        { name: t('Legal and Notary Fees'), value: 0 },
        { name: t('Administrative Costs'), value: 0 },
        { name: t('Utilities and Services'), value: 0 },
        { name: t('Property Taxes'), value: 0 },
        { name: t('Staff Salaries'), value: 0 },
        { name: t('Other Expenses'), value: 0 }
    ]);

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    useEffect(() => {
        // Fetch assets data from API or database
        fetchAssetsData(); // This will refetch and update the state
    }, [userId, API_URL]);

    useEffect(() => {
        if (tabIndex === 1) {
            filterAssets(assetsData, 'Sold', setFilteredSoldAssets);
        } else if (tabIndex === 2) {
            filterAssets(assetsData, 'Leased', setFilteredLeasedAssets);
        }
    }, [tabIndex, assetsData]);

    const calculateSummary = async (data) => {
        const relevantAssets = data.filter(asset => asset.status === 'Sold' || asset.status === 'Leased');
        const totalAssets = relevantAssets.length;
        const totalValue = relevantAssets.reduce((sum, asset) => sum + Number(asset.price), 0);
        const totalProfit = relevantAssets.reduce((sum, asset) => {
            let profit;
            if (asset.status === 'Leased') {
                profit = calculateLeasedProfit(Number(asset.price), Number(asset.commission), Number(asset.agentCommission));
            } else {
                profit = calculateProfit(Number(asset.price), Number(asset.commission), Number(asset.agentCommission));
            }
            console.log(`Asset: ${asset.name}, Status: ${asset.status}, Profit: ${profit}`); // Log each asset's profit
            return sum + profit;
        }, 0);

        const totalAgentExpense = relevantAssets.reduce((sum, asset) => {
            return sum + (asset.agentCommission ? Number(asset.price) * (Number(asset.agentCommission) / 100) : 0);
        }, 0);

        console.log("Calculated total profit:", totalProfit); // Log calculated total profit

        setTotalAssets(totalAssets);
        setTotalValue(totalValue);
        setTotalProfit(Number(totalProfit.toFixed(2))); // Round to two decimal places
        setTotalAgentExpense(Number(totalAgentExpense.toFixed(2))); // Round to two decimal places

        // Calculate top-performing assets
        const sortedAssets = relevantAssets.sort((a, b) => {
            const profitA = a.status === 'Leased' ? calculateLeasedProfit(Number(a.price), Number(a.commission), Number(a.agentCommission)) : calculateProfit(Number(a.price), Number(a.commission), Number(a.agentCommission));
            const profitB = b.status === 'Leased' ? calculateLeasedProfit(Number(b.price), Number(b.commission), Number(b.agentCommission)) : calculateProfit(Number(b.price), Number(b.commission), Number(b.agentCommission));
            return profitB - profitA;
        });
        setTopAssets(sortedAssets.slice(0, topAssetsCount));

        // Calculate agent performance data
        const agentPerformance = relevantAssets.reduce((acc, asset) => {
            const agent = asset.sold_by || asset.leased_by || 'Unknown';
            const profit = asset.status === 'Leased' ? calculateLeasedProfit(Number(asset.price), Number(asset.commission), Number(asset.agentCommission)) : calculateProfit(Number(asset.price), Number(asset.commission), Number(asset.agentCommission));
            if (!acc[agent]) {
                acc[agent] = 0;
            }
            acc[agent] += profit;
            return acc;
        }, {});

        const agentPerformanceArray = Object.keys(agentPerformance).map(agent => ({
            agent,
            profit: agentPerformance[agent].toFixed(2)
        }));

        // Sort the agent performance data by profit in descending order
        agentPerformanceArray.sort((a, b) => b.profit - a.profit);

        setAgentPerformanceData(agentPerformanceArray);

        // Calculate asset distribution
        const assetDistributionData = [
            { name: 'Sold', value: data.filter(asset => asset.status === 'Sold').length },
            { name: 'Leased', value: data.filter(asset => asset.status === 'Leased').length },
            { name: 'New', value: data.filter(asset => asset.status === 'New').length },
            { name: 'Pre-contract', value: data.filter(asset => asset.status === 'Pre-contract').length },
            { name: 'Published', value: data.filter(asset => asset.status === 'Published').length }
        ];
        setAssetDistribution(assetDistributionData);

        // Calculate profit over time
        const profitOverTimeData = data.reduce((acc, asset) => {
            const date = asset.profitDate ? new Date(asset.profitDate) : null;
            if (date && !isNaN(date.getTime())) {
                const formattedDate = format(date, 'yyyy-MM');
                const profit = asset.status === 'Leased' ? calculateLeasedProfit(Number(asset.price), Number(asset.commission), Number(asset.agentCommission)) : calculateProfit(Number(asset.price), Number(asset.commission), Number(asset.agentCommission));
                if (!acc[formattedDate]) {
                    acc[formattedDate] = 0;
                }
                acc[formattedDate] += profit;
            }
            return acc;
        }, {});

        // Ensure all months are included in the data for the current year
        const allMonths = Array.from({ length: 12 }, (_, i) => format(new Date(currentYear, i, 1), 'yyyy-MM'));
        const profitOverTimeArray = allMonths.map(month => ({
            date: month,
            profit: profitOverTimeData[month] || 0
        }));

        // Ensure the data is sorted by date
        profitOverTimeArray.sort((a, b) => new Date(a.date) - new Date(b.date));

        setProfitOverTime(profitOverTimeArray);
    };

    const handleYearChange = (direction) => {
        setCurrentYear(prevYear => {
            const newYear = prevYear + direction;
            return newYear >= new Date().getFullYear() ? newYear : prevYear;
        });
    };

    useEffect(() => {
        calculateSummary(assetsData);
    }, [currentYear, assetsData]);

    const filterAssets = async (data, status, setFilteredAssets) => {
        const filtered = await Promise.all(data.filter(asset => asset.status === status)
            .map(async (asset) => {
                const agentUsername = asset.sold_by || asset.leased_by || 'N/A';
                return {
                    name: asset.name,
                    price: asset.price,
                    commission: asset.commission || (status === 'Leased' ? '50.00' : '0.00'),
                    agentCommission: asset.agentCommission,
                    profit: status === 'Leased' ? calculateLeasedProfit(asset.price, asset.commission, asset.agentCommission) : calculateProfit(asset.price, asset.commission, asset.agentCommission),
                    agent: agentUsername,
                    assetId: asset.id,
                    soldBy: asset.sold_by,
                    leasedBy: asset.leased_by,
                    status: asset.status,
                    verified: asset.verified
                };
            }));
        setFilteredAssets(filtered);
    };

    const calculateProfit = (price, commission, agentCommission) => {
        const totalCommissionPercentage = commission - (agentCommission || 0);
        return price * (totalCommissionPercentage / 100);
    };

    const calculateLeasedProfit = (price, commission, agentCommission) => {
        const commissionPercentage = commission || 50.00; // Use asset.commission first, default to 50.00 if not present
        const baseProfit = price * (commissionPercentage / 100);
        const agentCommissionAmount = agentCommission ? price * (agentCommission / 100) : 0;
        return baseProfit - agentCommissionAmount;
    };

    const handleSearchChange = (term) => {
        setSearchTerm(term);
    };

    const handleVerify = async (row, isVerified) => {
        try {
            const payload = {
                verified: isVerified,
                status: isVerified ? row.status : 'New',
                sold_by: row.soldBy || null, // Preserve existing value or set null
                leased_by: row.leasedBy || null, // Preserve existing value or set null
            };
    
            await fetch(`${API_URL}/api/data/${row.assetId}/verify`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(payload),
            });
    
            if (isVerified) {
                setVerifiedAssets(prev => new Set(prev).add(row.assetId));
                setFilteredSoldAssets(prev =>
                    prev.map(asset =>
                        asset.assetId === row.assetId ? { ...asset, verified: true } : asset
                    )
                );
                setFilteredLeasedAssets(prev =>
                    prev.map(asset =>
                        asset.assetId === row.assetId ? { ...asset, verified: true } : asset
                    )
                );
            } else {
                setFilteredSoldAssets(prev => prev.filter(asset => asset.assetId !== row.assetId));
                setFilteredLeasedAssets(prev => prev.filter(asset => asset.assetId !== row.assetId));
                fetchAssetsData();
            }
        } catch (error) {
            console.error('Error verifying asset:', error);
            alert('Failed to verify asset. Please try again.');
        }
    };
    
    // Function to refetch assets data from API and update both states
    const fetchAssetsData = async () => {
        const response = await fetch(`${API_URL}/api/users/${userId}/assets`);
        const data = await response.json();
        setAssetsData(data);

        calculateSummary(data);
    
        // Set verified assets
        const verifiedSet = new Set(data.filter(asset => asset.verified).map(asset => asset.id));
        setVerifiedAssets(verifiedSet);
    };
    
    const handleAgentCommissionChange = async (assetId, newCommission, setFilteredAssets) => {
        // Update the local state with the new commission value
        setFilteredAssets(prevAssets =>
            prevAssets.map(asset =>
                asset.assetId === assetId ? { ...asset, agentCommission: newCommission } : asset
            )
        );
    };

    const handleAgentCommissionBlur = async (assetId, newCommission, setFilteredAssets) => {
        // Ensure the commission is a valid decimal number
        let parsedCommission = parseFloat(newCommission);
    
        // If the commission is not a valid number, reset to 0.00
        if (isNaN(parsedCommission)) {
            parsedCommission = 0.00;
        }
    
        console.log('Parsed Commission:', parsedCommission.toFixed(2)); // Print out the parsed commission
    
        try {
            // Log the value being sent to the backend
            console.log('Sending agentCommission to backend:', parsedCommission.toFixed(2));

            // Send the API request with the parsed decimal value
            await fetch(`${API_URL}/api/data/${assetId}/update-commission`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ agentCommission: parsedCommission.toFixed(2) })
            });
    
            // Update the local state with the new commission
            setFilteredAssets(prevAssets =>
                prevAssets.map(asset =>
                    asset.assetId === assetId ? { ...asset, agentCommission: parsedCommission.toFixed(2), profit: asset.status === 'Leased' ? calculateLeasedProfit(asset.price, asset.commission, parsedCommission) : calculateProfit(asset.price, asset.commission, parsedCommission) } : asset
                )
            );
    
            // Refetch assets data to ensure consistency
            fetchAssetsData();
        } catch (error) {
            console.error('Error updating agent commission:', error);
            alert('Failed to update agent commission. Please try again.');
        }
    };

    const handleCommissionChange = async (assetId, newCommission, setFilteredAssets) => {
        // Update the local state with the new commission value
        setFilteredAssets(prevAssets =>
            prevAssets.map(asset =>
                asset.assetId === assetId ? { ...asset, commission: newCommission } : asset
            )
        );
    };

    const handleCommissionBlur = async (assetId, newCommission, setFilteredAssets) => {
        // Ensure the commission is a valid decimal number
        let parsedCommission = parseFloat(newCommission);
    
        // If the commission is not a valid number, reset to default values
        if (isNaN(parsedCommission)) {
            parsedCommission = assetId.status === 'Leased' ? 50.00 : 2.00;
        }
    
        console.log('Parsed Commission:', parsedCommission.toFixed(2)); // Print out the parsed commission
    
        try {
            // Log the value being sent to the backend
            console.log('Sending commission to backend:', parsedCommission.toFixed(2));

            // Send the API request with the parsed decimal value
            await fetch(`${API_URL}/api/data/${assetId}/update-commission`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ commission: parsedCommission.toFixed(2) })
            });
    
            // Update the local state with the new commission
            setFilteredAssets(prevAssets => {
                const updatedAssets = prevAssets.map(asset =>
                    asset.assetId === assetId ? { ...asset, commission: parsedCommission.toFixed(2), profit: asset.status === 'Leased' ? calculateLeasedProfit(asset.price, parsedCommission, asset.agentCommission) : calculateProfit(asset.price, parsedCommission, asset.agentCommission) } : asset
                );
                console.log('Updated Assets:', updatedAssets); // Log the updated assets
                return updatedAssets;
            });
    
            // Refetch assets data to ensure consistency
            fetchAssetsData();
        } catch (error) {
            console.error('Error updating commission:', error);
            alert('Failed to update commission. Please try again.');
        }
    };

    const handleTopAssetsCountChange = (count) => {
        setTopAssetsCount(count);
    };

    const handleAssetClick = (asset) => {
        setSelectedAsset(asset); // Set the selected asset when clicked
    };

    useEffect(() => {
        calculateSummary(assetsData);
    }, [topAssetsCount]);

    useEffect(() => {
        // Detect if the user is on a mobile device
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.includes('android') || userAgent.includes('iphone')) {
            setIsMobile(true);
        }
    }, []);

    const COLORS = ['#1B998B', '#829AB1', '#D0A98F', '#715B86', '#A5A58D', '#446A78  ', '#927C6B  ', '#4F5D73  ', '#B6C2AD  ', '#848B76 '];

    const handleExpenseChange = (index, newValue) => {
        const updatedExpenses = [...expenseBreakdown];
        updatedExpenses[index].value = parseFloat(newValue) || 0;
        setExpenseBreakdown(updatedExpenses);
    };

    const renderPieChart = () => {
        const totalAssets = assetDistribution.reduce((sum, entry) => sum + entry.value, 0);
        if (totalAssets === 0) {
            return (
                <Typography variant="body1" style={{ color: '#FFF', textAlign: 'center', marginTop: '20px' }}>
                    {t("No assets available")}
                </Typography>
            );
        }

        return (
            <ResponsiveContainer width="50%" height={300} style={{ marginTop: '10px' }}>
                <PieChart>
                    <Pie data={assetDistribution} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={100} fill="#8884d8" label>
                        {assetDistribution.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
        );
    };

    const renderDonutChart = () => {
        const totalExpenses = expenseBreakdown.reduce((sum, entry) => sum + entry.value, 0);
        if (totalExpenses === 0) {
            return (
                <Typography variant="body1" style={{ color: '#FFF', textAlign: 'center', marginTop: '20px' }}>
                    {t("No expenses available")}
                </Typography>
            );
        } else {
            return (
                <ResponsiveContainer width="100%" height={300} style={{ marginTop: '10px' }}>
                    <PieChart>
                        <Pie
                            data={expenseBreakdown} // Include all entries, even those with zero value
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            innerRadius={50}
                            outerRadius={100}
                            fill="#8884d8"
                            label
                        >
                            {expenseBreakdown.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
            );
        }
    };

    useEffect(() => {
        // Update the expense breakdown with the total agent expenses
        setExpenseBreakdown(prevExpenses => {
            const updatedExpenses = [...prevExpenses];
            const agentCommissionIndex = updatedExpenses.findIndex(expense => expense.name === 'Agent Commissions');
            if (agentCommissionIndex !== -1) {
                updatedExpenses[agentCommissionIndex].value = totalAgentExpense;
            }
            return updatedExpenses;
        });
    }, [totalAgentExpense]);

    if (isMobile) {
        return (
            <>
                <FinanceContentMobile userId={userId} permissions={permissions} filteredSoldAssets= {filteredSoldAssets} filteredLeasedAssets={filteredLeasedAssets} contactsData={contactsData} assetTypes={assetTypes} setSelectedAsset={setSelectedAsset} selectedAsset={selectedAsset} setContactsData={setContactsData} setUpdateTrigger={setUpdateTrigger} handleCommissionChange={handleCommissionChange} handleCommissionBlur={handleCommissionBlur} setFilteredSoldAssets={setFilteredSoldAssets} handleAgentCommissionChange={handleAgentCommissionChange} handleAgentCommissionBlur={handleAgentCommissionBlur} setFilteredLeasedAssets={setFilteredLeasedAssets}/>
            </>
        );
    } else {
        return (
            <ThemeProvider theme={theme}>
                {/* <FinanceSearchBar updateSearchTerm={handleSearchChange} financeCountMessage={financeCountMessage} /> */}
                <AppBar position="fixed" style={{ top: '50px', zIndex: 98, width: '900px', maxWidth: 'calc(100% - 120px)', transform: 'translateX(-50%)', backgroundColor: 'transparent', left: '52%' }}>
                    <Tabs value={tabIndex} onChange={handleTabChange} TabIndicatorProps={{ style: { backgroundColor: 'rgb(0, 208, 189)' } }}>
                        <Tab label={t("Overview")} />
                        <Tab label={t("Sold")} />
                        <Tab label={t("Leased")} />
                    </Tabs>
                </AppBar>
                <Box p={3} sx={{
                    position: 'fixed',
                    width: '920px',
                    top: '126px',
                    maxWidth: 'calc(100% - 120px)',
                    transform: 'translateX(-50%)',
                    left: '52%',
                    height: 'calc(100vh - 176px)',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '3px',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: '#4A4F5E',
                        borderRadius: '15px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        background: '#4A4F5E',
                        boxShadow: 'inset 0 0 5px grey',
                    }
                }}>
                    {tabIndex === 1 && (
                        <TableContainer component={Paper} style={{ backgroundColor: 'transparent' }}>
                            <Table>
                                <TableHead>
                                    <TableRow style={{ backgroundColor: 'rgb(74, 79, 94)' }}>
                                        <TableCell style={{ color: 'white' }}>{t("Asset Name")}</TableCell>
                                        <TableCell style={{ color: 'white' }}>{t("Cost")}</TableCell>
                                        <TableCell style={{ color: 'white' }}>{t("Commission")}</TableCell>
                                        <TableCell style={{ color: 'white' }}>{t("Agent Commission")}</TableCell>
                                        <TableCell style={{ color: 'white' }}>{t("Profit")}</TableCell>
                                        <TableCell style={{ color: 'white' }}>{t("Sold By")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredSoldAssets.map((row, index) => (
                                        <TableRow key={index} style={{ backgroundColor: '#1C2138 ' }}>
                                            <TableCell style={{ color: 'white' }}>{row.name}</TableCell>
                                            <TableCell style={{ color: 'white' }}>€{row.price}</TableCell>
                                            <TableCell style={{ color: 'white' }}>
                                                {isAdmin ? (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <TextField
                                                            type="number"
                                                            value={row.commission}
                                                            onChange={(e) => handleCommissionChange(row.assetId, e.target.value, setFilteredSoldAssets)}
                                                            onBlur={(e) => handleCommissionBlur(row.assetId, e.target.value, setFilteredSoldAssets)}
                                                            inputProps={{ style: { color: 'white', width: '70px' }, step: "0.01" }} // Allow decimal values
                                                            placeholder="2.00" // Show default commission as placeholder
                                                        />
                                                        <span style={{ color: 'white', marginLeft: '5px' }}>%</span>
                                                    </div>
                                                ) : (
                                                    `${row.commission}%`
                                                )}
                                            </TableCell>
                                            <TableCell style={{ color: 'white' }}>
                                                {isAdmin ? (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <TextField
                                                            type="number"
                                                            value={row.agentCommission}
                                                            onChange={(e) => handleAgentCommissionChange(row.assetId, e.target.value, setFilteredSoldAssets)}
                                                            onBlur={(e) => handleAgentCommissionBlur(row.assetId, e.target.value, setFilteredSoldAssets)}
                                                            inputProps={{ style: { color: 'white', width: '70px' }, step: "0.01" }} // Allow decimal values
                                                            placeholder="0.00" // Show default agent commission as placeholder
                                                        />
                                                        <span style={{ color: 'white', marginLeft: '5px' }}>%</span>
                                                    </div>
                                                ) : (
                                                    `${row.agentCommission}%`
                                                )}
                                            </TableCell>
                                            <TableCell style={{ color: 'white' }}>€{(row.profit !== undefined ? row.profit.toFixed(2) : '0.00')}</TableCell>
                                            <TableCell style={{ color: 'white' }}>
                                                {row.agent}
                                                {isAdmin && row.agent !== 'N/A' && row.status === 'Sold' && !verifiedAssets.has(row.assetId) && (
                                                    <>
                                                        <IconButton onClick={() => handleVerify(row, true)}><Check style={{ color: 'green' }} /></IconButton>
                                                        <IconButton onClick={() => handleVerify(row, false)}><Close style={{ color: 'red' }} /></IconButton>
                                                    </>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                    {tabIndex === 2 && (
                        <TableContainer component={Paper} style={{ backgroundColor: 'transparent' }}>
                            <Table>
                                <TableHead>
                                    <TableRow style={{ backgroundColor: 'rgb(74, 79, 94)' }}>
                                        <TableCell style={{ color: 'white' }}>{t("Asset Name")}</TableCell>
                                        <TableCell style={{ color: 'white' }}>{t("Cost")}</TableCell>
                                        <TableCell style={{ color: 'white' }}>{t("Commission")}</TableCell>
                                        <TableCell style={{ color: 'white' }}>{t("Agent Commission")}</TableCell>
                                        <TableCell style={{ color: 'white' }}>{t("Profit")}</TableCell>
                                        <TableCell style={{ color: 'white' }}>{t("Leased By")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredLeasedAssets.map((row, index) => (
                                        <TableRow key={index} style={{ backgroundColor: '#1C2138 ' }}>
                                            <TableCell style={{ color: 'white' }}>{row.name}</TableCell>
                                            <TableCell style={{ color: 'white' }}>€{row.price}</TableCell>
                                            <TableCell style={{ color: 'white' }}>
                                                {isAdmin ? (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <TextField
                                                            type="number"
                                                            value={row.commission}
                                                            onChange={(e) => handleCommissionChange(row.assetId, e.target.value, setFilteredLeasedAssets)}
                                                            onBlur={(e) => handleCommissionBlur(row.assetId, e.target.value, setFilteredLeasedAssets)}
                                                            inputProps={{ style: { color: 'white', width: '70px' }, step: "0.01" }} // Allow decimal values
                                                            placeholder="50.00" // Show default commission as placeholder
                                                        />
                                                        <span style={{ color: 'white', marginLeft: '5px' }}>%</span>
                                                    </div>
                                                ) : (
                                                    `${row.commission}%`
                                                )}
                                            </TableCell>
                                            <TableCell style={{ color: 'white' }}>
                                                {isAdmin ? (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <TextField
                                                            type="number"
                                                            value={row.agentCommission}
                                                            onChange={(e) => handleAgentCommissionChange(row.assetId, e.target.value, setFilteredLeasedAssets)}
                                                            onBlur={(e) => handleAgentCommissionBlur(row.assetId, e.target.value, setFilteredLeasedAssets)}
                                                            inputProps={{ style: { color: 'white', width: '70px'  }, step: "0.01" }} // Allow decimal values
                                                            placeholder="0.00" // Show default agent commission as placeholder
                                                        />
                                                        <span style={{ color: 'white', marginLeft: '5px' }}>%</span>
                                                    </div>
                                                ) : (
                                                    `${row.agentCommission}%`
                                                )}
                                            </TableCell>
                                            <TableCell style={{ color: 'white' }}>€{(row.profit !== undefined ? row.profit.toFixed(2) : '0.00')}</TableCell>
                                            <TableCell style={{ color: 'white' }}>
                                                {row.agent}
                                                {isAdmin && row.agent !== 'N/A' && row.status === 'Leased' && !verifiedAssets.has(row.assetId) && (
                                                    <>
                                                        <IconButton onClick={() => handleVerify(row, true)}><Check style={{ color: 'green' }} /></IconButton>
                                                        <IconButton onClick={() => handleVerify(row, false)}><Close style={{ color: 'red' }} /></IconButton>
                                                    </>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                    {tabIndex === 0 && (
                        <Box>
                            <Grid container spacing={3} style={{ marginTop: '10px' }}>
                                <Grid item xs={12} sm={4}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6" style={{ color: '#00D0BD' }}>
                                                {t("Total Assets")}
                                            </Typography>
                                            <Typography variant="body1" style={{ color: '#FFF' }}>
                                                {totalAssets}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6" style={{ color: '#00D0BD' }}>
                                                {t("Total Agent Expenses")}
                                            </Typography>
                                            <Typography variant="body1" style={{ color: '#FFF' }}>
                                                €{totalAgentExpense.toFixed(2)}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6" style={{ color: '#00D0BD' }}>
                                                {t("Total Profit")}
                                            </Typography>
                                            <Typography variant="body1" style={{ color: '#FFF' }}>
                                                €{totalProfit.toFixed(2)}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            <Typography variant="h6" style={{ color: '#00D0BD', marginTop: '20px' }}>
                                {t("Top Performing Assets")}
                            </Typography>
                            <TableContainer component={Paper} style={{ marginTop: '10px', backgroundColor: 'transparent', maxHeight: '323px', overflowY: 'auto' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: 'rgb(74, 79, 94)' }}>
                                            <TableCell style={{ color: 'white' }}>{t("Asset Name")}</TableCell>
                                            <TableCell style={{ color: 'white' }}>{t("Profit")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {topAssets.map((asset, index) => (
                                            <TableRow key={index} style={{ backgroundColor: '#1C2138 ' }}>
                                                <TableCell style={{ color: 'white', cursor: 'pointer' }} onClick={() => handleAssetClick(asset)}>{asset.name}</TableCell>
                                                <TableCell style={{ color: 'white' }}>€{(asset.status === 'Leased' ? calculateLeasedProfit(Number(asset.price), Number(asset.commission), Number(asset.agentCommission)) : calculateProfit(Number(asset.price), Number(asset.commission), Number(asset.agentCommission))).toFixed(2)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                                <Typography variant="body1" style={{ color: '#FFF', fontWeight: 500, fontSize: '16px', fontStyle: 'normal', marginRight: '10px', fontFamily: 'Montserrat' }}>
                                    {t("Show Top:")}
                                </Typography>
                                <Button variant="text" style={{ borderColor: '#FFF', color: topAssetsCount === 5 ? '#00D0BD' : '#FFF', marginRight: '5px', padding: '5px 10px', minWidth: '5px', maxWidth: '30px' }} onClick={() => handleTopAssetsCountChange(5)}>
                                    5
                                </Button>
                                <Button variant="text" style={{ borderColor: '#FFF', color: topAssetsCount === 10 ? '#00D0BD' : '#FFF', marginRight: '5px', padding: '5px 10px', minWidth: '5px', maxWidth: '30px'  }} onClick={() => handleTopAssetsCountChange(10)}>
                                    10
                                </Button>
                                <Button variant="text" style={{ borderColor: '#FFF', color: topAssetsCount === 20 ? '#00D0BD' : '#FFF', padding: '5px 10px', minWidth: '4px', maxWidth: '30px'  }} onClick={() => handleTopAssetsCountChange(20)}>
                                    20
                                </Button>
                            </Box>
                            <Typography variant="h6" style={{ color: '#00D0BD', marginTop: '20px' }}>
                                {t("Performance Graph")}
                            </Typography>
                            <ResponsiveContainer width="100%" height={300} style={{ marginTop: '10px' }}>
                                <BarChart data={[{ name: t("Total Agent Expenses"), value: totalAgentExpense }, { name: t("Total Profit"), value: totalProfit }]}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="value" fill="#8884d8" />
                                </BarChart>
                            </ResponsiveContainer>
                            <Typography variant="h6" style={{ color: '#00D0BD', marginTop: '20px' }}>
                                {t("Asset Distribution")}
                            </Typography>
                            <Box display="flex" alignItems="center" justifyContent="flex-start">
                                {renderPieChart()}
                                <Box mt={2}>
                                    {assetDistribution.map((entry, index) => (
                                        <Typography key={index} variant="body1" style={{ color: COLORS[index % COLORS.length], fontWeight: 500, fontSize: '16px', fontStyle: 'normal', fontFamily: 'Montserrat' }}>
                                            {t(entry.name)}: {entry.value}
                                        </Typography>
                                    ))}
                                </Box>
                            </Box>
                            <Typography variant="h6" style={{ color: '#00D0BD', marginTop: '20px' }}>
                                {t("Profit Over The Years")}
                            </Typography>
                            <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
                                <IconButton onClick={() => handleYearChange(-1)}>
                                    <ChevronLeftIcon style={{ color: '#00D0BD' }} />
                                </IconButton>
                                <Typography variant="h6" style={{ color: '#FFF' }}>
                                    {currentYear}
                                </Typography>
                                <IconButton onClick={() => handleYearChange(1)}>
                                    <ChevronRightIcon style={{ color: '#00D0BD' }} />
                                </IconButton>
                            </Box>
                            <ResponsiveContainer width="100%" height={300} style={{ marginTop: '10px' }}>
                                <BarChart data={profitOverTime}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis 
                                        dataKey="date" 
                                        tickFormatter={(date) => {
                                            if (!date) return '';
                                            const [year, month] = date.split('-');
                                            if (!year || !month) return '';
                                            return format(new Date(year, month - 1), 'MMM');
                                        }}
                                    />
                                    <YAxis />
                                    <Tooltip formatter={(value) => `€${value.toFixed(2)}`} labelFormatter={(label) => `Date: ${label}`} />
                                    <Legend />
                                    <Bar dataKey="profit" fill="#8884d8" />
                                </BarChart>
                            </ResponsiveContainer>
                            <Typography variant="h6" style={{
                                color: '#00D0BD',
                                fontWeight: 500,
                                fontSize: '16px',
                                fontStyle: 'normal',
                                width: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                fontFamily: 'Montserrat'
                            }}>{t("Agent Performance")}</Typography>
                            <ResponsiveContainer width="100%" height={300} style={{ marginTop: '10px' }}>
                                <BarChart data={agentPerformanceData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="agent" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="profit" fill="#82ca9d" />
                                </BarChart>
                            </ResponsiveContainer>
                            <Typography variant="h6" style={{ color: '#00D0BD', marginTop: '20px' }}>
                            {t("Expense Breakdown")}
                        </Typography>
                            <Box display="flex" alignItems="center" justifyContent="flex-start">
                          
                        {renderDonutChart()}
                  
                    <Box mt={2} ml={2}>
                        <Grid container spacing={2}>
                            {expenseBreakdown.map((entry, index) => (
                                <Grid item xs={12} sm={6} key={index}>
                                    <Box display="flex" alignItems="center" mb={1}>
                                        <Typography variant="body1" style={{ color: COLORS[index % COLORS.length], fontWeight: 500, fontSize: '16px', fontStyle: 'normal', fontFamily: 'Montserrat', marginRight: '10px' }}>
                                            {t(entry.name)}:
                                        </Typography>
                                        <TextField
                                            type="number"
                                            value={entry.value}
                                            onChange={(e) => handleExpenseChange(index, e.target.value)}
                                            inputProps={{ style: { color: '#FFF', width: '70px' }, step: "0.01" }}
                                            style={{ color: '#FFF' }}
                                            InputProps={{
                                                startAdornment: <Typography style={{ color: '#FFF', marginRight: '5px' }}>€</Typography>
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
                        </Box>
                    )}
                </Box>
                {selectedAsset && (
                    <AssetDetailsModal isOpen={true} selectedAsset={selectedAsset} setSelectedAsset={setSelectedAsset} onClose={() => setSelectedAsset(null)} images={selectedAsset.images} name={selectedAsset.name} price={selectedAsset.price} status = {selectedAsset.status} description={selectedAsset.description}  id={selectedAsset.id} squareFootage={selectedAsset.squareFootage} AssetType={selectedAsset.AssetType} noOfRooms={selectedAsset.noOfRooms} total_floor_count={selectedAsset.total_floor_count} floor={selectedAsset.floor} furniture={selectedAsset.furniture} asset_condition={selectedAsset.asset_condition} heating={selectedAsset.heating} amenities={selectedAsset.amenities} township={selectedAsset.township} address={selectedAsset.address} rent={selectedAsset.rent} sale={selectedAsset.sale} assetTypes={assetTypes} contactsData={contactsData} userId={userId} notes = {selectedAsset.notes} setUpdateTrigger={setUpdateTrigger}  setContactsData={setContactsData} availability_start_date={selectedAsset.availability_start_date} availability_end_date={selectedAsset.availability_end_date}/>
                   
                )}            </ThemeProvider>        );    }
};
export default FinanceContent;