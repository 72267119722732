// Importing necessary libraries and components
import React, { useEffect, useState } from 'react';
import { IconButton, Typography, Popover, List, Button, Grid } from "@material-ui/core";
import '../styles/SearchBar.css';
import { ListItem, ListItemText } from '@mui/material';
import '../styles/HomePage.css';
import AssetDetailsModal from './AssetDetailsModal';
import RenderGridItem from './RenderGridItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from "react-i18next";
import CheckCircle from '../assets/CheckCircle';
import CheckedAssets from '../assets/CheckedAssets';
import UncheckAssets from '../assets/UncheckAssets';
import UncheckCircle from '../assets/UncheckCircle';
import axios from 'axios';
import CheckAssetCircle from '../assets/CheckAssetCircle';
import RenderListItem from './RenderListItem';

// MainContent component
const MainContent = ({ searchTerm, filteredNumberOfRooms, filteredPriceRange, filteredAssetType, filteredAmenities , isChecked, filteredLanguages, filteredHidden, filteredFurniture, filteredSaleOrRent, filteredAssetCondition,  availabilityFilter , filteredHeating,  filteredStatus, onToogleSettingsComponent, showSettingsComponent, selectedAssets, setSelectedAssets, showSelectedOnly, setShowSelectedOnly, userId, assetTypes, assets, selectedAsset, setSelectedAsset, contactsData, setHiddenAssetsCount, setContactsData, handleListItemClick, handleGridItemClick, updateTrigger, setUpdateTrigger}) => {
  // MainContent component
  const [activeButton, setActiveButton] = useState('grid');
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [databaseData, setDatabaseData] = useState([]);
  const [loading, setLoading] = useState(true); // New state for loading
  const [error, setError] = useState(null); // New state for error handling
  const [selectedSortOption, setSelectedSortOption] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false); // New state to track if all items are selected
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

  const handleCheckboxChange = (data, isChecked, isSelectAllChecked) => {
    // Logic to update selectedAssets state based on checkbox change
    if (isChecked) {
      setSelectedAssets([...selectedAssets, data]);
    } else if (isSelectAllChecked) {
      if (!isAllSelected) {
        setSelectedAssets([...filteredData]); 
        console.log("all selected assets:" , setSelectedAssets)
        setIsAllSelected(true);
      } else {
        setSelectedAssets([]);
        setIsAllSelected(false);
        console.log("all  assets deselected:" , setSelectedAssets)
      }
    } else {
      setSelectedAssets(selectedAssets.filter(asset => asset.id !== data.id));
      console.log("all  assets deselected:" , setSelectedAssets)
      setIsAllSelected(false);
    }
  };
  useEffect(() => {
    console.log("Selected assets:", selectedAssets);
  }, [selectedAssets]);

  useEffect(() => {
    // Detect if the user is on a mobile device
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('android') || userAgent.includes('iphone')) {
      setIsMobile(true);
    }
  }, []);

    // Render the button text based on selection state
    const renderButtonText = () => {
      if (selectedAssets.length === 0) {
        return (
          <span style={{ fontFamily: 'Montserrat', display: 'flex', alignItems: 'center', }}>
             {t('Select all')} <CheckCircle style={{ marginLeft: '10px' }} />
            </span>
        );
      } else if (isAllSelected) {
        return (
          <span style={{ fontFamily: 'Montserrat', display: 'flex', alignItems: 'center' }}>
           {t('Deselect all')}  <UncheckCircle style={{ marginLeft: '5px' }}/> 
            </span>
        );
      } else {
        return (
          <span style={{ fontFamily: 'Montserrat', display: 'flex', alignItems: 'center' }}>
            {t('Select all')} <CheckCircle  style={{ marginLeft: '10px' }}/>
          </span>
        );
      }
    };
    useEffect(() => {
      // This code will run whenever showSelectedOnly changes
    }, [showSelectedOnly]);
    const renderButtonIcon = () => {
      if (showSettingsComponent) {
        return <UncheckAssets  onClick={handleUnselectAll}/>;
      } else {
        return selectedAssets.length > 0 ? <CheckedAssets /> : null;
      }
    };
    
    const [isUnselectAllInProgress, setIsUnselectAllInProgress] = useState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
      if (isUnselectAllInProgress) {
        setShowSelectedOnly(false);
        setIsUnselectAllInProgress(false);
      }
    }, [isUnselectAllInProgress]);
    
    const handleUnselectAll = () => {
      console.log('filteredData before unselecting all:', filteredData);
      setSelectedAssets([]);
      setIsAllSelected(false);
      setIsUnselectAllInProgress(true);
      console.log('filteredData after unselecting all:', filteredData);
    };
  
  const { t } = useTranslation();
  
    // Function to handle button click and set the active button
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleSelectAll = () => {
    handleCheckboxChange(null, false, true); 

  };

  // Function to handle sort button click and set the anchor element for popover
  const handleSortButtonClick = (event) => {
    setOpen(true);
  };
  // After: Using a ref to avoid findDOMNode
const sortButtonRef = React.useRef(null);

  const handleSortOptionClick = (sortOption) => {
    console.log(`Sorting by: ${sortOption}`);
    let sortedData = [];
          // Sorting data based on the selected sort option
    switch (sortOption) {
      case 'Most Recent':
        sortedData = [...databaseData].sort((a, b) => b.id - a.id);
        break;
      case 'Oldest First':
        sortedData = [...databaseData].sort((a, b) => a.id - b.id);
        break;
      case 'Lowest Price':
        sortedData = [...databaseData].sort((a, b) => extractNumericValue(a.price) - extractNumericValue(b.price));
        break;
      case 'Highest Price':
        sortedData = [...databaseData].sort((a, b) => extractNumericValue(b.price) - extractNumericValue(a.price));
        break;
      case 'A-Z':
        sortedData = [...databaseData].sort((a, b) => a.name.localeCompare(b.name));
        break;
      case 'Z-A':
        sortedData = [...databaseData].sort((a, b) => b.name.localeCompare(a.name));
        break;
      default:
        sortedData = [...databaseData];
    }
      // Sorting logic remains the same
    setDatabaseData(sortedData);
    setSelectedSortOption(sortOption); // Update the selected sort option
    setOpen(false); // Close the popover after selection
  };

   // Function to extract numeric value from a string
  const extractNumericValue = (priceString) => {
    const numericPart = priceString.match(/\d+\.\d+/); // Extracts a floating-point number
    return numericPart ? parseFloat(numericPart[0]) : 0; // Converts to number, defaulting to 0 if not found
  };


 // Closing the popover
  const handleClosePopover = () => {
    setOpen(false);
  };
  useEffect(() => {
    // Your logic to render items based on selectedAssets
  }, [selectedAssets]);
  const id = open ? 'sort-by-popover' : undefined;

  const fetchImageWithCache = async (imageUrl, cache) => {
    const baseUrl = imageUrl.split('?')[0];
    try {
      let response = await cache.match(baseUrl);
  
      if (response) {
        console.log(`Fetching ${baseUrl} from cache`);
        const blob = await response.blob();
        return { originalUrl: imageUrl, blobUrl: URL.createObjectURL(blob) };
      } else {
        console.log(`Fetching ${imageUrl} from network`);
        const fetchResponse = await fetch(imageUrl, { mode: 'cors' });
  
        if (fetchResponse.ok) {
          const blob = await fetchResponse.blob();
          await cache.put(baseUrl, new Response(blob));
          return { originalUrl: imageUrl, blobUrl: URL.createObjectURL(blob) };
        } else {
          console.error('Network response was not ok for URL:', imageUrl);
          throw new Error('Network response was not ok.');
        }
      }
    } catch (error) {
      console.error('Error fetching image:', imageUrl, error);
      throw error;
    }
  };
 // Fetching data from the database

 useEffect(() => {
  axios.get(`${API_URL}/api/users/${userId}/assets`)
    .then(async response => {
      const data = response.data;
      console.log(data);

      let cache;
      try {
        if ('caches' in window) { // Check if 'caches' is available in the global scope
          cache = await caches.open('asset-images');
          console.log('Cache opened:', cache);
        } else {
          console.log('Caches API not available.');
        }
      } catch (error) {
        console.error('Error opening cache:', error);
      }

      const translatedData = await Promise.all(data.map(async item => ({
        ...item,
        images: await Promise.all(item.images.map(async image => {
          const { originalUrl, blobUrl } = cache ? await fetchImageWithCache(image.image_url, cache) : { originalUrl: image.image_url, blobUrl: image.image_url };
          return {
            imageId: image.imageId,
            originalImageUrl: originalUrl, // Store the original URL
            imageUrl: blobUrl, // Store the Blob URL for display
          };
        })),
        name: item.name,
        moreInfo: item.moreinfo,
        info: item.info,
        price: item.price,
        description: t(item.description),
        squareFootage: t(item.squareFootage),
        notes: t(item.notes),
      })));

      translatedData.forEach(item => {
        item.images.forEach(image => {
          console.log(image.imageUrl);
        });
      });

      setDatabaseData(translatedData);
      setLoading(false);

      const hiddenAssetsCount = translatedData.filter(item => item.hidden).length;
      setHiddenAssetsCount(hiddenAssetsCount);
    })
    .catch(error => {
      console.error('Error fetching data', error);
      setError(error);
      setLoading(false);
    });
}, [t, userId, assets, updateTrigger]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  };

  // Filter data based on the search term
  const filteredData = databaseData.filter((item) => {
    const searchTermMatch = (
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.squareFootage.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.price.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    const numberOfRoomsMatch = (
      filteredNumberOfRooms > 0 ? Math.abs(item.noOfRooms - filteredNumberOfRooms) < 0.0001 : true
    );

    const priceRangeMatch = (
      extractNumericValue(item.price) >= filteredPriceRange[0] &&
      extractNumericValue(item.price) <= filteredPriceRange[1]
    );
  
    const assetTypeMatch = (
      filteredAssetType === '' ? true : 
      (typeof item.AssetType === 'string' && item.AssetType.toLowerCase() === filteredAssetType.toLowerCase())
    );

    const amenitiesMatch = (
      filteredAmenities.length === 0 ? true : filteredAmenities.some(amenity => item.amenities.includes(amenity))
    );

    const languageMatch = (
      filteredLanguages.length === 0 ? true : (item.hostLanguage && filteredLanguages.some(language => item.hostLanguage.includes(language)))
    );
    
    const furnitureMatch = (
      filteredFurniture.length === 0 ? true : (item.furniture && filteredFurniture.some(furniture => item.furniture.includes(furniture)))
    );
 
    const saleOrRentMatch = filteredSaleOrRent.length === 0 ? true : (
      (filteredSaleOrRent.includes('sale') && item.sale) ||
      (filteredSaleOrRent.includes('rent') && item.rent)
    );
    
    const isAvailable = () => {
      // Convert start and end dates to Date objects or null if they are falsy
      const assetStart = item.availability_start_date ? new Date(item.availability_start_date) : null;
      const assetEnd = item.availability_end_date ? new Date(item.availability_end_date) : null;
      const filterStart = availabilityFilter.startDate ? new Date(availabilityFilter.startDate) : null;
      const filterEnd = availabilityFilter.endDate ? new Date(availabilityFilter.endDate) : null;
    
      // If both asset start and end dates are null, consider the asset always available
      if (!assetStart && !assetEnd) {
        return true;
      }
    
      // If asset dates are valid, check against the filter dates
      if ((assetStart && isNaN(assetStart.getTime())) || (assetEnd && isNaN(assetEnd.getTime()))) {
        console.error('Invalid asset start or end date format');
        return false;
      }
    
      // Debugging: Log dates to ensure they're being compared correctly
      // console.log(`Asset Start: ${assetStart}, Asset End: ${assetEnd}, Filter Start: ${filterStart}, Filter End: ${filterEnd}`);
    
      // The asset is considered unavailable if:
      // 1. The filter start date is within the asset's non-availability period.
      // 2. The filter end date is within the asset's non-availability period.
      // 3. The asset's non-availability period is within the filter start and end dates.
      // If filter dates are null, they are ignored in the comparison.
      return !(
        (filterStart && assetEnd && filterStart <= assetEnd && (assetStart ? filterStart >= assetStart : true)) ||
        (filterEnd && assetStart && filterEnd >= assetStart && (assetEnd ? filterEnd <= assetEnd : true)) ||
        (filterStart && filterEnd && assetStart && assetEnd && filterStart <= assetStart && filterEnd >= assetEnd)
      );
    };
    const assetConditionMatch = (
      filteredAssetCondition.length === 0 ? true : (item.asset_condition && filteredAssetCondition.some(assetCondition => item.asset_condition.includes(assetCondition)))
    );

    const heatingMatch = (
      filteredHeating.length === 0 ? true : (item.heating && filteredHeating.some(heating => item.heating.includes(heating)))
    );

    const statusMatch = (
      filteredStatus.length === 0 ? true: (item.status && filteredStatus.some(status => item.status.includes(status)))
    );

    const hiddenMatch = (
      filteredHidden.length === 0 ? !item.hidden : item.hidden
    );

    return searchTermMatch && numberOfRoomsMatch  && priceRangeMatch && assetTypeMatch && amenitiesMatch && languageMatch && furnitureMatch & assetConditionMatch & heatingMatch & statusMatch & hiddenMatch & saleOrRentMatch && isAvailable();
  });
    // Handle checkbox change
    const handleChange = (data, isChecked, isSelectAllChecked) => {
      const newIsChecked = !isChecked; // Toggle isChecked state
  
      if (newIsChecked) {
        console.log('Selected asset:', data);
        handleCheckboxChange(data, newIsChecked); // Pass the updated state to the parent componenty
      } else if (isSelectAllChecked){
        handleCheckboxChange(data, !isSelectAllChecked); // Pass the updated state to the parent componenty
      
      } else {
        console.log('Deselected asset:', data);
      }
    };


  // This function also takes an object 'data' as an argument and returns a ListItem component from Material UI.
  // The ListItem is used to display each asset in a list layout.
  // The 'key' prop is given a unique identifier from the 'data' object.
  // The 'style' prop is used to apply styles to the ListItem.
  // Inside the ListItem, several ListItemText components are rendered to display various details about the asset, such as its name, more info, info, and price.
  // The 'primary' prop of ListItemText is used to specify the main text of the list item.
  // The 'style' prop is used to apply styles to the text.
const renderListItem = (data) => (
  <ListItem key={data.id} style={{ borderRadius: '10px', border: '1px solid #1F2C41',  marginBottom: '5px', cursor: 'pointer'}}>
    <ListItemText primary={data.name} style={{ color: '#00D0BD', fontWeight: 500 , paddingRight: '10px', maxWidth: '200px'}} />
    <ListItemText primary={data.address} style={{ color: '#F4F4F4', fontWeight: 300 , paddingRight: '10px' , maxWidth: '250px'}} />
    <ListItemText primary={`${data.squareFootage} ft²`} style={{ color: '#F4F4F4', fontWeight: 300 , paddingRight: '10px', maxWidth: '250px'}} />
    <ListItemText primary={`$${data.price}`}style={{ color: '#F4F4F4', fontWeight: 500 , maxWidth: '150px'}}/>
          <IconButton onClick={() => handleChange(data)} style={{ position: 'absolute', top: 0, right: '40px' }}>
          <CheckAssetCircle isChecked={isChecked} handleChange={() => handleCheckboxChange(data, !isChecked)}  style={{ left: '20%', position: 'relative' ,  width: '30px', height: '30px'}} />
          </IconButton>
  </ListItem>
);

const assetsCountMessage = (
  (searchTerm || filteredNumberOfRooms > 0 || filteredAmenities.length > 0 || filteredLanguages.length > 0  || filteredFurniture.length > 0 || filteredSaleOrRent > 0 ||  filteredAssetCondition.length > 0 || filteredHeating.length > 0 || filteredStatus.length > 0 || filteredAssetType || (filteredPriceRange[0] > 0 || filteredPriceRange[1] < 10000) || filteredHidden.length > 0)  &&
  filteredData.length > 0
)
  ? ` ${filteredData.length} ${filteredData.length === 1 ? t('asset found') : t('assets found')}`
  : '';


   // Render different content based on the device type
   const renderContent = () => {
    if (isMobile) {
      return (
        <React.Fragment>
        <div style={{ position: 'relative', overflowY: 'scroll', height: '100%' }}>
        <div style={{ display: 'flex', paddingTop: '32px', justifyContent: 'flex-start', position: 'fixed', top: window.innerWidth < 1100 ? '80px' : '63px', left: window.innerWidth < 450 ? '7%' : '54%' }}>
          {assetsCountMessage === '' && (
            <Typography variant="h6" style={{ display: 'flex', color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', flexShrink: 0, height: '21px', paddingLeft: '15px', paddingTop: '15px' }}>
              {t('All assets')}
            </Typography>
          )}
          <Typography variant="body1" style={{ color: '#FFFFFF', textAlign: 'center', margin: '10px 0' }}>
            {assetsCountMessage}
          </Typography>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', margin: 'auto', maxWidth: 'calc(100% - 1px)', width: '920px', paddingTop: '52px', justifyContent: 'flex-start', position: 'fixed', top: '100px', left: '52%', transform: 'translateX(-50%)' }}>
          <Button
            ref={sortButtonRef}
            onClick={handleSortButtonClick}
            style={{
              color: "#FFFFFF",
              flexShrink: 0,
              maxWidth: '225px',
              width: '50%',
              height: '40px',
              borderRadius: '10px',
              border: '1px solid #1F2C41',
              background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingLeft: '10px',
              paddingRight: '10px',
              marginLeft: '20px'
            }}
          >
            <Typography variant="h6" style={{ display: 'flex', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize',  }}>
              {selectedSortOption ? t(selectedSortOption) : t('Sort By')}
            </Typography>
            <KeyboardArrowDownIcon />
          </Button>
          <IconButton variant="h6" onClick={() => handleSelectAll()}  style={{  color: '#FFFFFF', cursor: 'pointer', zIndex: '1000', marginRight: '30px', marginTop: '15px', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', flexShrink: 0, height: '21px',paddingTop: '5px', textTransform: 'capitalize', width: '160px' }}>
          {renderButtonText()}
            </IconButton>
        </div>
            <Popover
              id={id}
              open={open}
              anchorEl={sortButtonRef.current}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
            <List style={{ background: 'linear-gradient(180deg, #2A2F3D 100%, rgba(65, 0, 51, 0.00) 100%)', flexDirection: 'column', display: 'flex', width: '225px', }}>
            <Button onClick={() => handleSortOptionClick('Most Recent')} style={{ color: '#FFFFFF', textTransform: 'capitalize' }} className="my-button">
            {t('Most Recent')}
            </Button>
            <Button onClick={() => handleSortOptionClick('Oldest First')} style={{ color: '#FFFFFF', textTransform: 'capitalize' }} className="my-button">
            {t('Oldest First')}
            </Button>
            <Button onClick={() => handleSortOptionClick('Lowest Price')} style={{ color: '#FFFFFF' , textTransform: 'capitalize'}} className="my-button">
            {t('Lowest Price')}
            </Button>
            <Button onClick={() => handleSortOptionClick('Highest Price')} style={{ color: '#FFFFFF', textTransform: 'capitalize' }} className="my-button">
            {t('Highest Price')}
            </Button>
            <Button onClick={() => handleSortOptionClick('A-Z')} style={{ color: '#FFFFFF', textTransform: 'capitalize' }} className="my-button">
            {t('A-Z')}
            </Button>
            <Button onClick={() => handleSortOptionClick('Z-A')} style={{ color: '#FFFFFF', textTransform: 'capitalize'}} className="my-button">
            {t('Z-A')}
            </Button>
            </List>
    
            </Popover>
            <div  className="scrollbar2" style={{  width: '100%', margin: 'auto',  cursor: 'pointer', position: 'fixed', top: '210px', 
              left: '0', overflowY: 'scroll',  // Enable vertical scrolling
              height: 'calc(100% - 270px)', // Set a fixed height
              zIndex: 1000,}}>
            {filteredData.length === 0 ? (
                databaseData.length === 0 ? (
                  <Typography variant="body1" style={{ color: '#FFFFFF' }}>
                    {t('You have no assets.')}
                  </Typography>
                ) : (
                  <Typography variant="body1" style={{ color: '#FFFFFF' }}>
                    {t('No data matches your search.')}
                  </Typography>
                )
              ) : (
              activeButton === 'grid' ? (
                          <Grid container spacing={0} justifyContent='center' >
              {filteredData.map((data) => (
                  <RenderGridItem key={data.id} data={data} handleSelectAll={handleSelectAll} handleGridItemClick={handleGridItemClick} handleCheckboxChange={handleCheckboxChange} isChecked={selectedAssets.some(asset => asset.id === data.id)}/>
              ))}
                </Grid>
              ) : (
                /* Add the code for rendering items as a list here */
                <List>
                  {filteredData.map(renderListItem)}
                  
                </List>
              )
            )}
          </div>
          {selectedAssets.length > 0 && (
        <div style={{
          position: 'fixed',
          bottom: '70px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1000,
          width: '350px',
          display: 'flex',
          justifyContent: 'center'
        }}>
      <Button
        variant="contained"
        onClick={onToogleSettingsComponent}
        startIcon={renderButtonIcon()}
        style={{
          textTransform: 'capitalize',
          color: '#FFF',
          backgroundColor: 'rgb(26, 32, 51)', // Set the background color
          fontFamily: 'Montserrat',
          boxShadow: '0px 4px 15px rgba(0, 208, 189, 0)', // Initial box shadow
          transition: 'box-shadow 0.3s ease-in-out, color 0.3s ease-in-out', // Smooth transition effect for box shadow and text color
          fontSize: '18px',
          borderRadius: '20px'
        }}
        onMouseOver={(e) => {
          e.currentTarget.style.boxShadow = '0px 6px 20px rgba(0, 208, 189, 0.9)'; // Adjusted box shadow on hover
          e.currentTarget.style.color = 'rgb(0, 208, 189)';// Change text color on hover
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.boxShadow = '0px 4px 15px rgba(0, 208, 189, 0)'; // Restore box shadow on mouse out
          e.currentTarget.style.color = 'white'; // Restore text color on mouse out
        }}
      >

{selectedAssets.length === databaseData.length
        ? t('AllAssetsSelected', { count: selectedAssets.length })
        : selectedAssets.length === 1
          ? t('OneAssetSelected', { count: selectedAssets.length })
          : t('MultipleAssetsSelected', { count: selectedAssets.length })}
      </Button>
        </div>
      )}
          {selectedAsset && (
        <div>
          {/* Add this line for debugging */}
          {console.log(selectedAsset)}
          <AssetDetailsModal isOpen={true} selectedAsset={selectedAsset} setSelectedAsset={setSelectedAsset} onClose={() => setSelectedAsset(null)} images={selectedAsset.images} name={selectedAsset.name} price={selectedAsset.price} status = {selectedAsset.status} description={selectedAsset.description}  id={selectedAsset.id} squareFootage={selectedAsset.squareFootage} AssetType={selectedAsset.AssetType} noOfRooms={selectedAsset.noOfRooms} total_floor_count={selectedAsset.total_floor_count} floor={selectedAsset.floor} furniture={selectedAsset.furniture} asset_condition={selectedAsset.asset_condition} heating={selectedAsset.heating} amenities={selectedAsset.amenities} township={selectedAsset.township} address={selectedAsset.address} rent={selectedAsset.rent} sale={selectedAsset.sale} assetTypes={assetTypes} contactsData={contactsData} userId={userId} notes = {selectedAsset.notes} setUpdateTrigger={setUpdateTrigger}  setContactsData={setContactsData} availability_start_date={selectedAsset.availability_start_date} availability_end_date={selectedAsset.availability_end_date}/>
        </div>
      )}
        </div>
        </React.Fragment>
      );
    } else {
      return (
        // Placeholder for desktop content
        <React.Fragment>
        <div style={{ display: 'flex', flexDirection: 'row', margin: 'auto', maxWidth: 'calc(100% - 120px)', width: '950px', paddingTop: '32px', justifyContent: 'space-between', position: 'fixed', top: window.innerWidth < 1100 ? '70px' : '72px', left: window.innerWidth < 450 ? '40%' : '54%',
          transform: 'translateX(-50%)' }}>
        <React.Fragment>
          {assetsCountMessage === '' && (
            <Typography variant="h6" style={{ color: '#FFFFFF', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', flexShrink: 0, height: '21px', paddingLeft: '15px', paddingTop: '5px' }}>
             {t('All assets')}
            </Typography>
          )}
          <Typography variant="body1" style={{ color: '#FFFFFF', textAlign: 'center', margin: '10px 0' }}>
            {assetsCountMessage}
          </Typography>
        </React.Fragment>

            <Button
              ref={sortButtonRef}
              onClick={handleSortButtonClick}
              style={{
                color: "#FFFFFF",
                flexShrink: 0,
                maxWidth: '225px',
                width: '50%',
                height: '40px',
                borderRadius: '10px',
                border: '1px solid #1F2C41',
                background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
                marginRight: '90px',
                marginTop: '15px'
              }}
            >
              <Typography variant="h6" style={{ display: 'flex', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize',  }}>
              {selectedSortOption ? t(selectedSortOption) : t('Sort By')}
              </Typography>
              <KeyboardArrowDownIcon />
            </Button>
        </div>
          <div style={{ display: 'flex', flexDirection: 'row', margin: 'auto', maxWidth: 'calc(100% - 120px)', width: '920px', marginTop: '52px', justifyContent: 'space-between', position: 'fixed', top: '100px', left: '52%', 
              transform: 'translateX(-50%)' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <IconButton onClick={() => handleButtonClick('grid')} style={{ color: activeButton === 'grid' ? "#00D0BD" : "#FFFFFF" }}>
                <Typography variant="h6" style={{ display: 'flex', fontFamily: 'Montserrat', fontSize: '20px',
                  fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', flexShrink: 0, width: '44px', height: '24px',
                  textDecoration: activeButton === 'grid' ? 'underline' : 'none' }}>
                  Grid
                </Typography>
              </IconButton>
              <IconButton onClick={() => handleButtonClick('list')} style={{ color: activeButton === 'list' ? "#00D0BD" : "#FFFFFF" }}>
                <Typography variant="h6" style={{ display: 'flex', fontFamily: 'Montserrat', fontSize: '20px',
                  fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', flexShrink: 0, width: '60px', height: '24px',
                  textDecoration: activeButton === 'list' ? 'underline' : 'none' }}>
                  {t('List')}
                </Typography>
              </IconButton>
            </div>
            <IconButton variant="h6" onClick={() => handleSelectAll()}  style={{  color: '#FFFFFF', cursor: 'pointer', zIndex: '1000', marginRight: '30px', marginTop: '25px', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', flexShrink: 0, height: '21px',paddingTop: '5px', textTransform: 'capitalize', width: '160px' }}>
          {renderButtonText()}
            </IconButton>
          </div>
            <Popover
              open={open}
              anchorEl={sortButtonRef.current}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
            <List style={{ background: 'linear-gradient(180deg, #2A2F3D 100%, rgba(65, 0, 51, 0.00) 100%)', flexDirection: 'column', display: 'flex', width: '225px', }}>
            <Button onClick={() => handleSortOptionClick('Most Recent')} style={{ color: '#FFFFFF', textTransform: 'capitalize' }} className="my-button">
                {t('Most Recent')}
            </Button>
            <Button onClick={() => handleSortOptionClick('Oldest First')} style={{ color: '#FFFFFF', textTransform: 'capitalize' }} className="my-button">
                {t('Oldest First')}
            </Button>
            <Button onClick={() => handleSortOptionClick('Lowest Price')} style={{ color: '#FFFFFF' , textTransform: 'capitalize'}} className="my-button">
                {t('Lowest Price')}
            </Button>
            <Button onClick={() => handleSortOptionClick('Highest Price')} style={{ color: '#FFFFFF', textTransform: 'capitalize' }} className="my-button">
                {t('Highest Price')}
            </Button>
            <Button onClick={() => handleSortOptionClick('A-Z')} style={{ color: '#FFFFFF', textTransform: 'capitalize' }} className="my-button">
                {t('A-Z')}
            </Button>
            <Button onClick={() => handleSortOptionClick('Z-A')} style={{ color: '#FFFFFF', textTransform: 'capitalize'}} className="my-button">
                {t('Z-A')}
            </Button>
            </List>
    
            </Popover>
            <div  className="scrollbar2" style={{  flexDirection: 'row', width: '100%', margin: 'auto', maxWidth: 'calc(100% - 100px)', width: '920px', cursor: 'pointer', position: 'fixed', top: '150px', left: '51%', 
              transform: 'translateX(-50%)' , overflowY: 'scroll',  // Enable vertical scrolling
              maxHeight: '75vh', marginTop: '60px', marginLeft: '20px' ,// Set a fixed height
              zIndex: 1000,}}>
            {filteredData.length === 0 ? (
                databaseData.length === 0 ? (
                  <Typography variant="body1" style={{ color: '#FFFFFF' }}>
                    {t('You have no assets.')}
                  </Typography>
                ) : (
                  <Typography variant="body1" style={{ color: '#FFFFFF' }}>
                    {t('No data matches your search.')}
                  </Typography>
                )
              ) : (
              activeButton === 'grid' ? (
                          <Grid container spacing={0} justifyContent='flex-start' >
{(showSelectedOnly ? selectedAssets : (filteredData.length > 0 ? filteredData : databaseData).filter(item => filteredHidden.length > 0 ? true : !item.hidden)).map((data) => (
  <RenderGridItem key={data.id} data={data} handleSelectAll={handleSelectAll} handleGridItemClick={handleGridItemClick} handleCheckboxChange={handleCheckboxChange} isChecked={selectedAssets.some(asset => asset.id === data.id)}/>
))}
                </Grid>
              ) : (
                /* Add the code for rendering items as a list here */
                <List>
{(showSelectedOnly ? selectedAssets : (filteredData.length > 0 ? filteredData : databaseData).filter(item => !item.hidden)).map((data) => (
  <RenderListItem key={data.id} data={data} handleSelectAll={handleSelectAll} handleListItemClick={handleListItemClick} handleCheckboxChange={handleCheckboxChange} isChecked={selectedAssets.some(asset => asset.id === data.id)}/>
))}
                  </List>
              )
            )}
          </div>
                         {/* Conditionally render the button based on selectedAssets count */}
      {selectedAssets.length > 0 && (
        <div style={{
          position: 'fixed',
          bottom: '15px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1000
        }}>
      <Button
        variant="contained"
        onClick={onToogleSettingsComponent}
        startIcon={renderButtonIcon()}
        style={{
          textTransform: 'capitalize',
          color: '#FFF',
          backgroundColor: 'rgb(26, 32, 51)', // Set the background color
          fontFamily: 'Montserrat',
          boxShadow: '0px 4px 15px rgba(0, 208, 189, 0)', // Initial box shadow
          transition: 'box-shadow 0.3s ease-in-out, color 0.3s ease-in-out', // Smooth transition effect for box shadow and text color
          fontSize: '18px',
          borderRadius: '20px'
        }}
        onMouseOver={(e) => {
          e.currentTarget.style.boxShadow = '0px 6px 20px rgba(0, 208, 189, 0.9)'; // Adjusted box shadow on hover
          e.currentTarget.style.color = 'rgb(0, 208, 189)';// Change text color on hover
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.boxShadow = '0px 4px 15px rgba(0, 208, 189, 0)'; // Restore box shadow on mouse out
          e.currentTarget.style.color = 'white'; // Restore text color on mouse out
        }}
      >

{selectedAssets.length === databaseData.length
        ? t('AllAssetsSelected', { count: selectedAssets.length })
        : selectedAssets.length === 1
          ? t('OneAssetSelected', { count: selectedAssets.length })
          : t('MultipleAssetsSelected', { count: selectedAssets.length })}
      </Button>
        </div>
      )}
      {selectedAsset && (
        <div>
          {/* Add this line for debugging */}
          {console.log(selectedAsset)}
          <AssetDetailsModal isOpen={true} selectedAsset={selectedAsset} setSelectedAsset={setSelectedAsset} onClose={() => setSelectedAsset(null)} images={selectedAsset.images} name={selectedAsset.name} price={selectedAsset.price} status = {selectedAsset.status} description={selectedAsset.description}  id={selectedAsset.id} squareFootage={selectedAsset.squareFootage} AssetType={selectedAsset.AssetType} noOfRooms={selectedAsset.noOfRooms} total_floor_count={selectedAsset.total_floor_count} floor={selectedAsset.floor} furniture={selectedAsset.furniture} asset_condition={selectedAsset.asset_condition} heating={selectedAsset.heating} amenities={selectedAsset.amenities} township={selectedAsset.township} address={selectedAsset.address} rent={selectedAsset.rent} sale={selectedAsset.sale} assetTypes={assetTypes} contactsData={contactsData} userId={userId} notes = {selectedAsset.notes} setUpdateTrigger={setUpdateTrigger}  setContactsData={setContactsData} availability_start_date={selectedAsset.availability_start_date} availability_end_date={selectedAsset.availability_end_date}/>
        </div>
      )}
        </React.Fragment>
      );
    }
  };

  return (
    <React.Fragment>
      {renderContent()}
    </React.Fragment>
  );
};

export default MainContent;